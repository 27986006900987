import React, { Component } from "react";
import WebSocketHandler from "../../../core/web-socket/web-socket-handler";
import {
  DAYS_FROM_BEGIN_DATE,
  AVERAGE_DAYS_IN_A_MONTH,
  HOT_PLATE_USAGE,
  TIME_TO_BAKE_A_EGG,
  WASHING_MACHINE_JOB,
  HOUSE_HOLD_USAGE,
  CART_POWER_USAGE,
} from "../../config";
import {
  HighlightsItemComponent,
  HighlightsItemTypeEnum,
} from "../highlights-item/HighlightsItem";
import "./RightHighlights.css";

interface RightHighlightsComponentProps {}
interface RightHighlightsComponentState {
  activeIndex: number;
  closing: boolean;
  opening: boolean;
  totalYield: number;
}

export class RightHighlightsComponent extends Component<
  RightHighlightsComponentProps,
  RightHighlightsComponentState
> {
  constructor(props: RightHighlightsComponentProps) {
    super(props);

    this.state = {
      activeIndex: 0,
      closing: false,
      opening: true,
      totalYield: 0,
    };
  }

  /**
   * Component did mount.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public componentDidMount(): void {
    const timer = setInterval(() => this.intervalTick(), 30000);

    WebSocketHandler.getInstance().onNewTotalYield((totalYield) => {
      this.setState({
        ...this.state,
        totalYield,
      });
    });
  }

  /**
   * Interval tick.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private intervalTick(): void {
    const { activeIndex } = this.state;

    setTimeout(() => {
      this.setState({
        activeIndex: activeIndex + 1 > 1 ? 0 : activeIndex + 1,
        closing: false,
        opening: false,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          opening: true,
        });
      }, 10);
    }, 300);

    this.setState({ closing: true, activeIndex, opening: false });
  }

  private renderBakingAEgg(activeIndices: number[]): JSX.Element {
    const { activeIndex, closing, opening, totalYield } = this.state;
    const averageYieldInADay = totalYield / DAYS_FROM_BEGIN_DATE;
    const hotPlateUsageForOneEgg = (HOT_PLATE_USAGE / 60) * TIME_TO_BAKE_A_EGG;

    return (
      <HighlightsItemComponent
        type={HighlightsItemTypeEnum.BakingAEgg}
        value={new Intl.NumberFormat("nl-NL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(averageYieldInADay / hotPlateUsageForOneEgg)}
        description={
          "Eitjes kunnen we bakken met een gemiddelde dag opbrengst."
        }
        active={activeIndices.includes(activeIndex)}
        closing={closing}
        animated={true}
        opening={opening}
      />
    );
  }

  private renderWashingMachine(activeIndices: number[]): JSX.Element {
    const { activeIndex, closing, opening, totalYield } = this.state;
    const averageYieldInADay =
      (totalYield / DAYS_FROM_BEGIN_DATE) * AVERAGE_DAYS_IN_A_MONTH;

    return (
      <HighlightsItemComponent
        type={HighlightsItemTypeEnum.WashingMachine}
        value={new Intl.NumberFormat("nl-NL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(averageYieldInADay / WASHING_MACHINE_JOB)}
        description={
          "Wassen kunnen we draaien met een gemiddelde maand opbrengst."
        }
        active={activeIndices.includes(activeIndex)}
        closing={closing}
        animated={true}
        opening={opening}
      />
    );
  }

  private renderHouseholds(activeIndices: number[]): JSX.Element {
    const { activeIndex, closing, opening, totalYield } = this.state;
    const averageYieldInAYear =
      (totalYield / 1000 / DAYS_FROM_BEGIN_DATE) * 365;
    const houseHolder = averageYieldInAYear / HOUSE_HOLD_USAGE;

    return (
      <HighlightsItemComponent
        type={HighlightsItemTypeEnum.House}
        value={new Intl.NumberFormat("nl-NL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(houseHolder)}
        description={
          "Huishoudens kunnen wij van stroom voorzien met een gemiddelde jaar opbrengst."
        }
        active={activeIndices.includes(activeIndex)}
        closing={closing}
        animated={true}
        opening={opening}
      />
    );
  }

  private renderCartUsage(activeIndices: number[]): JSX.Element {
    const { activeIndex, closing, opening, totalYield } = this.state;

    const averageYieldInADay = totalYield / DAYS_FROM_BEGIN_DATE;
    const hoursInACartWithDayYield = averageYieldInADay / CART_POWER_USAGE;

    return (
      <HighlightsItemComponent
        type={HighlightsItemTypeEnum.ElectricCart}
        value={new Intl.NumberFormat("nl-NL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(hoursInACartWithDayYield)}
        description={"Uur kunnen wij rijden met een gemiddelde dag opbrengst."}
        active={activeIndices.includes(activeIndex)}
        closing={closing}
        animated={true}
        opening={opening}
      />
    );
  }

  /**
   * Render highlights component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    return (
      <div>
        <section className="highlights__right">
          {this.renderBakingAEgg([0])}
          {this.renderWashingMachine([0])}
          {this.renderHouseholds([1])}
          {this.renderCartUsage([1])}
        </section>
      </div>
    );
  }
}
