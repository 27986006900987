import React, { Component } from "react";
import "./TotalYield.css";
import solarPanel from "../../assets/images/solar-panels/solar-panel-sunny.svg";
import WebSocketHandler from "../../core/web-socket/web-socket-handler";

interface TotalYieldComponentProps {}
interface TotalYieldComponentState {
  totalYield: number;
}

export class TotalYieldComponent extends Component<
  TotalYieldComponentProps,
  TotalYieldComponentState
> {
  /**
   * Creates an instance of TotalYieldComponent.
   *
   * @param props
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  constructor(props: TotalYieldComponentProps) {
    super(props);

    this.state = {
      totalYield: 0,
    };
  }

  /**
   * On component did mount.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public componentDidMount(): void {
    WebSocketHandler.getInstance().onNewTotalYield((totalYield: number) =>
      this.setState({ totalYield: totalYield })
    );
  }

  /**
   * Render active yield component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    const { totalYield } = this.state;
    const formattedYield = new Intl.NumberFormat("nl-NL", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(totalYield / 1000);

    return (
      <section className="active-yield">
        <article className="active-yield__container">
          <figure className="active-yield__solar-panel-container">
            <img
              alt="Active solar panel yield"
              src={solarPanel}
              className="active-yield__solar-panel"
            />
          </figure>

          <h2 className="active-yield__value">
            <span className="active-yield__value-modifier">
              {formattedYield}
            </span>{" "}
            MWh Groene stroom
          </h2>
          <h3 className="active-yield__description">
            hebben wij inmiddels opgewekt!
          </h3>
        </article>
      </section>
    );
  }
}
