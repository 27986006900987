import React, { Component } from "react";
import "./Footer.css";
import logo from "../../assets/images/samen_de_zon.png";

export class FooterComponent extends Component {
  /**
   * Render footer component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    return (
      <footer className="footer">
        <img alt="Solarfields logo" src={logo} className="footer__logo" />
      </footer>
    );
  }
}
