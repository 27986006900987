import { ActiveYield } from "../interfaces/active-yield";

export default class WebSocketHandler {
  private static instance?: WebSocketHandler;
  private webSocket?: WebSocket;
  private activeYieldHandler?: (activeYield: ActiveYield) => void;
  private weeklyYieldHandler?: (weeklyYield: ActiveYield[]) => void;
  private totalYieldHandler?: ((totalYield: number) => void)[] = [];

  public static getInstance(): WebSocketHandler {
    if (this.instance === undefined) {
      this.instance = new WebSocketHandler();
    }
    return this.instance;
  }

  /**
   * Start web socket handler.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public start(): void {
    this.webSocket = new WebSocket("wss://dashboard.solarfields.nl/websocket");

    if (this.webSocket !== null) {
      this.webSocket.addEventListener("message", this.handleMessage);

      this.webSocket.onopen = this.onOpen;
    }
  }

  /**
   * Handle incoming message.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private handleMessage(this: WebSocket, event: MessageEvent<any>): void {
    const payload = JSON.parse(event.data);

    switch (payload.message) {
      case "ping":
        WebSocketHandler.getInstance().sendPong(this);
        break;
      case "active_yield":
        WebSocketHandler.getInstance().handleNewActiveYield(this, event);
        break;
      case "weekly_yield":
        WebSocketHandler.getInstance().handleNewWeeklyYield(this, event);
        break;
      case "total_yield":
        WebSocketHandler.getInstance().handleNewTotalYield(this, event);
        break;
      default:
        break;
    }
  }

  public sendPong(websocket: WebSocket): void {
    websocket.send(JSON.stringify({ message: "pong" }));
  }

  public handleNewActiveYield(
    websocket: WebSocket,
    event: MessageEvent<any>
  ): void {
    const payload = JSON.parse(event.data);

    if (this.activeYieldHandler) {
      this.activeYieldHandler(payload.yield);
    }
  }

  public handleNewWeeklyYield(
    websocket: WebSocket,
    event: MessageEvent<any>
  ): void {
    const payload = JSON.parse(event.data);

    if (this.weeklyYieldHandler) {
      this.weeklyYieldHandler(payload.yield);
    }
  }

  public handleNewTotalYield(
    websocket: WebSocket,
    event: MessageEvent<any>
  ): void {
    const payload = JSON.parse(event.data);

    this.totalYieldHandler?.forEach((handler) => handler(payload.yield));
  }

  public onNewActiveYield(handler: (activeYield: ActiveYield) => void): void {
    this.activeYieldHandler = handler;
  }

  public onNewWeeklyYield(handler: (weeklyYield: ActiveYield[]) => void): void {
    this.weeklyYieldHandler = handler;
  }

  public onNewTotalYield(handler: (totalYield: number) => void): void {
    this.totalYieldHandler = [...(this.totalYieldHandler ?? []), handler];
  }

  public onOpen(this: WebSocket, event: Event) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    this.send(
      JSON.stringify({
        message: "authenticate",
        authenticationToken: params.token,
      })
    );
  }
}
