import React, { Component } from "react";
import "./App.css";
import { FooterComponent } from "../footer/Footer";
import { HeaderComponent } from "../header/Header";
import { LeftHighlightsComponent } from "../highlights/left-highlights/LeftHighlights";
import { GraphComponent } from "../graph/Graph";
import { TotalYieldComponent } from "../total-yield/TotalYield";
import { RightHighlightsComponent } from "../highlights/right-highlights/RightHighlights";
import WebSocketHandler from "../../core/web-socket/web-socket-handler";

export class AppComponent extends Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    WebSocketHandler.getInstance().start();
  }

  /**
   * Render app component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    return (
      <main>
        <div className="container">
          <HeaderComponent />

          <div className="divider">
            <div className="divider__two-thirds">
              <LeftHighlightsComponent />

              <GraphComponent />
            </div>
            <div className="divider__one-thirds">
              <TotalYieldComponent />

              <RightHighlightsComponent />
            </div>
          </div>
        </div>

        <FooterComponent />
      </main>
    );
  }
}
