export const BEGIN_DATE = new Date(1618790400000);
export const DAYS_FROM_BEGIN_DATE = Math.floor(
  (new Date().getTime() - BEGIN_DATE.getTime()) / (1000 * 60 * 60 * 24)
);

export const HOUSE_HOLD_USAGE = 2.73; // MW(h) in one year
export const HOT_PLATE_USAGE = 1.8; // Kwh
export const TIME_TO_BAKE_A_EGG = 4;
export const WASHING_MACHINE_JOB = 69; // Kwh for one washing job
export const CARBON_CREATED_BY_KILO_WATT_HOUR = 0.39; // In kg
export const CART_POWER_USAGE = 4.8; // Kwh in power usage

export const AVERAGE_DAYS_IN_A_MONTH = 365 / 12;
