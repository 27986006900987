import React, { Component } from "react";
import WebSocketHandler from "../../../core/web-socket/web-socket-handler";
import { CARBON_CREATED_BY_KILO_WATT_HOUR } from "../../config";
import {
  HighlightsItemComponent,
  HighlightsItemTypeEnum,
} from "../highlights-item/HighlightsItem";
import "./LeftHighlights.css";

interface LeftHighlightsComponentProps {}
interface LeftHighlightsComponentState {
  totalYield: number;
}

export class LeftHighlightsComponent extends Component<
  LeftHighlightsComponentProps,
  LeftHighlightsComponentState
> {
  /**
   * Creates an instance of LeftHighlightsComponent.
   *
   * @param props
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  constructor(props: LeftHighlightsComponentProps) {
    super(props);

    this.state = {
      totalYield: 0,
    };
  }

  public componentDidMount(): void {
    WebSocketHandler.getInstance().onNewTotalYield((totalYield: number) =>
      this.setState({ totalYield })
    );
  }

  /**
   * Render highlights component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    const { totalYield } = this.state;

    const carbon = CARBON_CREATED_BY_KILO_WATT_HOUR * totalYield;
    const displayInTons = carbon > 100000;

    return (
      <section className="highlights__left">
        <HighlightsItemComponent
          type={HighlightsItemTypeEnum.ElectricCart}
          value={"100%"}
          description={"Van de energie voor de karts wekken we zelf op."}
        />
        <HighlightsItemComponent
          type={HighlightsItemTypeEnum.SolarPanel}
          value={"828"}
          description={"Zonnepanelen liggen er op dit dak."}
        />
        <HighlightsItemComponent
          type={HighlightsItemTypeEnum.CarbonDioxide}
          value={
            new Intl.NumberFormat("nl-NL", {
              minimumFractionDigits: displayInTons ? 2 : 0,
              maximumFractionDigits: displayInTons ? 2 : 0,
            }).format(displayInTons ? carbon / 1000 : carbon) +
            (displayInTons ? " t" : " kg")
          }
          description={
            "CO&sup2; uitstoot hebben we vermeden met onze zonnepanelen."
          }
        />
      </section>
    );
  }
}
