import React, { Component } from "react";
import "./Header.css";

export class HeaderComponent extends Component {
  /**
   * Render header component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    return (
      <header className="header">
        <h1 className="header__title">ZERO55</h1>

        {/* <nav className="header__navigation">
                    <ul className="header__navigation-list">
                        <li className="header__navigation-item header__navigation-item--active">
                            Realtime
                        </li>
                        <li className="header__navigation-item">
                            Weekelijks
                        </li>
                        <li className="header__navigation-item">
                            Maandelijks
                        </li>
                        <li className="header__navigation-item">
                            Jaarlijks
                        </li>
                    </ul>
                </nav> */}
      </header>
    );
  }
}
