import React, { Component } from "react";
import { VictoryChart, VictoryArea, VictoryAxis } from "victory";
import { ActiveYield } from "../../core/interfaces/active-yield";
import WebSocketHandler from "../../core/web-socket/web-socket-handler";
import "./Graph.css";

type GraphComponentProps = {};
type GraphComponentState = {
  data?: { x: number; y: number }[];
  graphHeight: number;
  graphWidth: number;
  activeYield: ActiveYield | null;
};

export class GraphComponent extends Component<
  GraphComponentProps,
  GraphComponentState
> {
  constructor(props: GraphComponentProps) {
    super(props);

    this.state = {
      data: [],
      activeYield: null,
      graphHeight: 0,
      graphWidth: 0,
    };
  }

  /**
   * On component did mount event.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public componentDidMount(): void {
    const element = document.getElementsByClassName("graph__content")[0];

    this.setState({
      ...this.state,
      graphHeight: element.clientHeight,
      graphWidth: element.clientWidth,
    });

    WebSocketHandler.getInstance().onNewWeeklyYield(
      (weeklyYield: ActiveYield[]) => this.handleNewWeeklyYield(weeklyYield)
    );

    WebSocketHandler.getInstance().onNewActiveYield(
      (activeYield: ActiveYield) =>
        this.setState({ ...this.state, activeYield })
    );
  }

  /**
   * Handle new incoming weekly yield.
   *
   * @param weeklyYield
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private handleNewWeeklyYield(weeklyYield: ActiveYield[]): void {
    this.setState({
      data: weeklyYield
        .filter(
          (dataPoint: ActiveYield) => new Date(dataPoint.timestamp) < new Date()
        )
        .map((dataPoint: ActiveYield) => ({
          x: dataPoint.timestamp,
          y: dataPoint.value,
        })),
    });
  }

  /**
   * Capitalize first letter.
   *
   * @param string
   *
   * @returns String.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Render X axis.
   *
   * @returns JSX element.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private renderXAxis(): JSX.Element {
    const axisDates = new Array(7).fill(0).map((_, index) => {
      const axisDate = new Date();
      axisDate.setDate(axisDate.getDate() - (6 - index));
      axisDate.setMinutes(0);
      axisDate.setSeconds(0);
      axisDate.setMilliseconds(0);

      if (
        axisDate.getDate() !== new Date().getDate() ||
        new Date().getHours() > 15
      ) {
        axisDate.setHours(15);
      } else {
        axisDate.setHours(new Date().getHours());
      }

      return axisDate;
    });

    return (
      <VictoryAxis
        tickValues={axisDates.map((date) => date.getTime())}
        tickFormat={(timestamp) => {
          const date = new Date(timestamp);

          return this.capitalizeFirstLetter(
            date.toLocaleDateString("nl-NL", { weekday: "long" })
          );
        }}
        style={{
          axisLabel: { fontFamily: "OpenSans" },
          tickLabels: { fontFamily: "OpenSans" },
        }}
      />
    );
  }

  /**
   * Get axis ticks for Y.
   *
   * @returns Array of numbers.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  private getYAxisTickValues(): number[] {
    const steps = 20;
    const { data } = this.state;
    const numberValues = data?.map((dataPoint): number =>
      Math.round(dataPoint.y)
    );

    if (numberValues === undefined || numberValues.length === 0) {
      return [];
    }

    const maxValue = Math.max(...numberValues);

    return new Array(Math.floor(maxValue / steps) + 1)
      .fill(0)
      .map((_, index) => {
        const value = steps * (index + 1);

        if (value > maxValue || value + steps / 2 > maxValue) {
          return maxValue;
        }
        return value;
      });
  }

  /**
   * Render graph component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    const { data, graphHeight, graphWidth, activeYield } = this.state;
    const currentYield = activeYield?.value ?? 0;

    return (
      <article className="graph">
        <header className="graph__header">
          <h2 className="graph__title">Opbrengst van de afgelopen week</h2>
        </header>

        <div className="graph__content">
          <VictoryChart height={graphHeight} width={graphWidth}>
            <VictoryArea
              data={data}
              style={{
                data: { fill: "#18adaf", stroke: "teal" },
              }}
              interpolation={"basis"}
            />

            {this.renderXAxis()}

            <VictoryAxis
              dependentAxis
              tickValues={this.getYAxisTickValues()}
              tickFormat={(tick) => {
                return `${tick.toString()} Kw`;
              }}
              style={{
                axisLabel: { fontFamily: "OpenSans" },
                tickLabels: { fontFamily: "OpenSans" },
              }}
            />
          </VictoryChart>

          <div className="graph-active-yield">
            <p className="graph-active-yield__title">Huidige opbrengst</p>

            <h3 className="graph-active-yield__yield">
              <i>
                {new Intl.NumberFormat("nl-NL", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(currentYield)}
              </i>{" "}
              kW
            </h3>
            <p className="graph-active-yield__sub-title">(per 5 minuten)</p>
          </div>
        </div>
      </article>
    );
  }
}
