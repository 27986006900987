import React, { Component } from "react";
import "./HighlightsItem.css";
import solarPanel from "../../../assets/images/highlights/solarpanel.svg";
import bakeAEgg from "../../../assets/images/highlights/bake_a_egg.svg";
import electricCart from "../../../assets/images/highlights/electric_cart.svg";
import washingMachine from "../../../assets/images/highlights/washing_machine.svg";
import house from "../../../assets/images/highlights/house.png";
import carbonDioxide from "../../../assets/images/highlights/co2.png";

export enum HighlightsItemTypeEnum {
  SolarPanel = "solar-panel",
  BakingAEgg = "baking-a-egg",
  ElectricCart = "electric-cart",
  WashingMachine = "washing-machine",
  House = "house",
  CarbonDioxide = "carbon-dioxide",
}

interface HighlightsItemComponentProps {
  type: string;
  value: string;
  description: string;
  active?: boolean;
  closing?: boolean;
  animated?: boolean;
  opening?: boolean;
}
interface HighlightsItemComponentState {}

export class HighlightsItemComponent extends Component<
  HighlightsItemComponentProps,
  HighlightsItemComponentState
> {
  /**
   * Creates an instance of HighlightsItemComponent.
   *
   * @param props
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  constructor(props: HighlightsItemComponentProps) {
    super(props);

    this.state = {};
  }

  private getImage(): string {
    const { type } = this.props;

    let image;

    switch (type) {
      case HighlightsItemTypeEnum.BakingAEgg:
        image = bakeAEgg;
        break;
      case HighlightsItemTypeEnum.ElectricCart:
        image = electricCart;
        break;
      case HighlightsItemTypeEnum.WashingMachine:
        image = washingMachine;
        break;
      case HighlightsItemTypeEnum.House:
        image = house;
        break;
      case HighlightsItemTypeEnum.CarbonDioxide:
        image = carbonDioxide;
        break;
      case HighlightsItemTypeEnum.SolarPanel:
      default:
        image = solarPanel;
        break;
    }

    return image;
  }

  /**
   * Create markup.
   *
   * @param description
   *
   * @returns Markup object
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   */
  private createMarkup(description: string) {
    return { __html: description };
  }

  /**
   * Render highlights item component.
   *
   * @author Niek van der Velde <niek@aimtofeel.com>
   * @version 1.0.0
   */
  public render(): JSX.Element {
    const {
      value,
      description,
      active,
      closing,
      animated,
      opening,
    } = this.props;

    return (
      <article
        className={`highlights-item ${
          animated ? "highlights-item__animated" : ""
        } ${active ? "highlights-item__visible" : ""} ${
          closing ? "highlights-item__closing" : ""
        } ${opening ? "highlights-item__animation-active" : ""}`}
      >
        <div className="highlights-item__container">
          <figure>
            <img
              className="highlights-item__image"
              alt="Zonepaneel"
              src={this.getImage()}
            />
          </figure>

          <h3 className="highlights-item__count">{value}</h3>

          <p
            className="highlights-item__description"
            dangerouslySetInnerHTML={this.createMarkup(description)}
          ></p>
        </div>
      </article>
    );
  }
}
